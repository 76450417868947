/* DArt rules */
.masthead__menu {
  background: url(../../icone_dcsatc.png) no-repeat;
  background-size: contain;
  padding-left: 60px;
}

/* changelogs */
.fix { color: red; }
.chg__bold { font-weight: 700; }
.enhancement { color: blue; }
.feature { color: green; }

.d-column {
  flex: 30%;
}

/* Clear floats after the columns */
.d-row {
  display: flex;
  align-items: center; 
  justify-content: center;
}
.d-quart-size {
  width: 25%;
}
.mermaid {
  font-size: small;
}

.d-full-width {
  width: 100%;
}